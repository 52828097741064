import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { Col, Row } from "react-bootstrap";
import { Constants } from "../../@types/Constants";
import { IGenericPageProps } from "./GenericPageProps";
import Layout from "../../components/global/Layout/Layout";
import { getParagraph } from "../../utils/paragraphHelpers";
import MediaBanner from "../../components/common/MediaBanner/MediaBanner";
import {
  ContentContainer,
  StyledContainer,
  StyledRow,
} from "./GenericPageHelpers";
import { IGenericParagraph } from "../../@types/IParagraph";
import ParagraphLayout from "../../components/common/ParagraphLayout/ParagraphLayout";
import SEO from "../../components/global/SEO/SEO";
import { addPageTypeInGTMDataLayer } from "../../utils/datalayers";
//import { addKoddiPageTag } from "../../utils/KoddiTagsGTM";

const GenericPage = (props: IGenericPageProps): JSX.Element => {
  const node = { ...props.data.nodeGenericPage };
  const paragraphs = node.relationships.paragraphs.map((paragraph) => {
    if (paragraph.type === "paragraph__photo_gallery") {
      return getParagraph(paragraph, {
        imgCountPerRow: 1,
      });
    } else {
      return getParagraph(paragraph, {});
    }
  });
  const pageBanner = paragraphs.filter((p: IGenericParagraph) => {
    return p
      ? p.props.paragraphtype === Constants.PARAGRAPH_PAGE_BANNER
      : false;
  });
  const content = props.content ? props.content.internal.content : null;
  const summary = node.body ? node.body.value : "";
  useEffect(() => {
    addPageTypeInGTMDataLayer("landing page");
  }, []);

  return (
    <Layout>
      <SEO
        title={node?.title}
        description={node?.field_meta_description}
        noIndex={node?.field_seo_noindex}
        noFollow={node?.field_seo_nofollow}
        Disallow={node?.field_seo_disallow}
        koddiTitle="GenericPage"
      />
      {node.relationships.field_banner && (
        <MediaBanner relationships={node.relationships.field_banner}>
          {node.heading && (
            <Row>
              <Col lg={5} className="text-center text-lg-left">
                <div
                  className="text-white"
                  dangerouslySetInnerHTML={{ __html: node.heading.value }}
                />
              </Col>
            </Row>
          )}
        </MediaBanner>
      )}
      {pageBanner}
      {(node.field_subtitle || summary) && (
        <ContentContainer>
          <StyledRow>
            {node.field_subtitle && (
              <Col>
                <h1>{node.field_subtitle}</h1>
              </Col>
            )}
            <Col>
              <p
                dangerouslySetInnerHTML={{ __html: summary }}
                className="font-weight-light description"
              />
            </Col>
          </StyledRow>
        </ContentContainer>
      )}

      <StyledContainer fluid="sm" className="pt-5 pb-5">
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </StyledContainer>
      <ParagraphLayout {...paragraphs} />
    </Layout>
  );
};
export const query = graphql`
  query ($slug: String!) {
    nodeGenericPage(path: { alias: { eq: $slug } }) {
      id
      path {
        alias
      }
      title
      field_subtitle
      field_meta_description
      field_seo_disallow
      field_seo_nofollow
      field_seo_noindex
      heading: field_heading {
        processed
        value
      }
      relationships {
        paragraphs: field_sections {
          type: __typename
          ...ParagraphBackground
          ...ParagraphBookNowBanner
          ...ParagraphFeaturedDestinations
          ...ParagraphFeaturedOffers
          ...ParagraphFeaturedProperties
          ...ParagraphFooterCta
          ...ParagraphFrequentlyAskedQuestions
          ...ParagraphHistory
          ...ParagraphMediaCta
          ...ParagraphMediaTiles
          ...ParagraphOffers
          ...ParagraphOurBrands
          ...ParagraphOurBrandsTeasers
          ...ParagraphPageBanner
          ...ParagraphPageIntro
          ...ParagraphPageTeaser
          ...ParagraphPartnershipBrands
          ...ParagraphSailDates
          ...ParagraphSiteOffers
          ...ParagraphSupportTiles
          ...ParagraphTeaserList
          ...ParagraphBody
          ...ParagraphCartAd
          ...ParagraphMembershipBenefits
          ...ParagraphMobileCta
          ...ParagraphPhotoGallery
          ...ParagraphVideoBlock
        }
        field_banner {
          relationships {
            desktopImage: field_media_image {
              publicUrl
              gatsbyImage(
                width: 1920
                height: 400
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
            mobileImage: field_media_image {
              publicUrl
              gatsbyImage(
                width: 490
                height: 200
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      body {
        value
      }
    }
  }
`;
export default GenericPage;
