// extracted by mini-css-extract-plugin
export var alt = "MediaBanner-module--alt--3ec6e";
export var banner = "MediaBanner-module--banner--78d19";
export var btn = "MediaBanner-module--btn--c8862";
export var description = "MediaBanner-module--description--df76d";
export var desktopOnly = "MediaBanner-module--desktopOnly--a80a0";
export var main = "MediaBanner-module--main--ff5d4";
export var mobileOnly = "MediaBanner-module--mobileOnly--c751c";
export var overlay = "MediaBanner-module--overlay--03a8c";
export var textContainer = "MediaBanner-module--textContainer--e63a9";
export var textLeft = "MediaBanner-module--textLeft--93784";
export var title = "MediaBanner-module--title--ab2d6";
export var visuallyHidden = "MediaBanner-module--visuallyHidden--7aafc";