// extracted by mini-css-extract-plugin
export var alt = "GenericPage-module--alt--aae37";
export var btnConditions = "GenericPage-module--btn-conditions--d9303";
export var container = "GenericPage-module--container--1ff9b";
export var contentContainer = "GenericPage-module--contentContainer--9ddd2";
export var description = "GenericPage-module--description--8da6f";
export var desktopOnly = "GenericPage-module--desktopOnly--7a1a8";
export var main = "GenericPage-module--main--09cf4";
export var mainLink = "GenericPage-module--mainLink--7996c";
export var mobileOnly = "GenericPage-module--mobileOnly--37976";
export var styledRow = "GenericPage-module--styledRow--6e5a7";
export var textLeft = "GenericPage-module--textLeft--a5f54";
export var titleContainer = "GenericPage-module--titleContainer--bc613";
export var visuallyHidden = "GenericPage-module--visuallyHidden--ec86e";